import React from 'react';
/**
 * Higher order component that provides file list data
 * @param {component} WrappedComponent
 * @param {string} requestType the type of request to be made. 'browse' or 'review'
 */
function withFilelist(WrappedComponent) {
	return class extends React.Component {
		registerFetchCallback = (cb) => {
			this.onFetchCallback = cb;
		};

		_onFetchHandler(data) {
			if (typeof this.onFetchCallback === 'function')
				this.onFetchCallback(data);
		}

		componentDidMount() {
			let { path } = this.props.match;
			let _requestType = path.indexOf('browse') > -1 ? 'browse' : 'review';

			if (process.env.NODE_ENV !== 'production') {
				const mockData = require('../../../mockResponseData.json');
				this._onFetchHandler(mockData[_requestType]);
			} else {
				// request object
				let _requestObject = {
					type: _requestType,
				};

				if (_requestType === 'review') {
					_requestObject.path = this.props.match.url;
				}
				// stringify the request object
				let _json = JSON.stringify(_requestObject);

				// make request
				fetch('/get-files.php', {
					method: 'post',
					body: _json,
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				})
					.then((res) => res.json())
					.then((json) => {
						this._onFetchHandler(json);
					});
			}
		}

		render() {
			return (
				<WrappedComponent
					registerFetchCallback={this.registerFetchCallback}
					{...this.props}
				/>
			);
		}
	};
}

export default withFilelist;
