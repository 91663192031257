import React from 'react';
import Container from '../_atoms/Container';
import InstructionalBox from '../_molecules/InstructionalBox';

/**
 * Index component.
 * Essentially if the ola path doesn't exist, then we'll redirect
 * to the Index which is empty.
 */
function Index() {
	return (
		<>
			<Container width='1200px' height='620px'>
				<InstructionalBox text='Nothing to see here.' />
			</Container>
		</>
	);
}

export default Index;
