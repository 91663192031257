import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

const PBarSlide = keyframes`
	from {background-position-x: 100%;}
	to {background-position-x: 0%;}
`;

const PBarWrapper = styled.div`
	height: 12px;
	border: none;
	border-radius: 6px;
	background-size: 198% 200%;
	background-color: #1f2933;
	background-image: repeating-linear-gradient(
		-45deg,
		transparent,
		transparent 10px,
		#9fb3c8 10px,
		#9fb3c8 20px
	);
	width: 100%;
	max-width: 380px;
	position: relative;
	overflow: hidden;
	border: 1px solid #1f2933;
	background-position-x: 50%;
	animation: 4s ${PBarSlide} infinite linear;
	box-shadow: 0 2px 2px #1d2025;

	${(props) =>
		props.isPaused &&
		css`
			animation-play-state: paused;
		`}

	/* highlight */
	&:before {
		content: '';
		width: 100%;
		height: 50%;
		position: absolute;
		top: 0%;
		left: 0;
		background: linear-gradient(
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0)
		);
		z-index: 2;
	}
`;

// progress bar fill
// this is intended to fake being the unfilled
// progress bar and instead of growing from 0 - 100% from the left
// it shrinks for 100% - 0 from the right
// this keeps the barberpole animation from changing sizes
const ProgressBarFill = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	background: #1f2933;
	position: absolute;
	right: 0;
`;

/**
 * Progress bar for OLA Banners
 */
class ProgressBar extends React.Component {
	static propTypes = {
		/**
		 * The ola root timeline object.
		 * This has to be exported via the first frame of the animation.
		 */
		olaRoot: PropTypes.object,
		/** Callback function for when the animation is finished. */
		onFinished: PropTypes.func,
		/**
		 * Whether the progress bar is paused or not.
		 * Used to animate the barberpole when playing and stop when not playing.
		 */
		isPaused: PropTypes.bool,
	};

	static defaultProps = {
		olaRoot: null,
		onFinished: null,
	};

	constructor(props) {
		super(props);
		this.state = {
			progress: 0,
			isFinished: false,
			isPaused: false,
		};

		this.onCanvasTick = this.onCanvasTick.bind(this);
		this.pause = this.pause.bind(this);
		this.start = this.start.bind(this);
		this.updateProgress = this.updateProgress.bind(this);

		if (this.props.olaRoot) {
			this.props.olaRoot.on('tick', this.onCanvasTick, this);
		}
	}

	componentDidMount() {
		this.updateProgress();
	}

	pause() {
		this.props.olaRoot.removeAllEventListeners('tick');
		this.setState({ isPaused: true });
	}

	start() {
		this.props.olaRoot.on('tick', this.onCanvasTick, this);
		this.setState({ isPaused: false });
	}

	updateProgress() {
		let _timeline = this.props.olaRoot;
		let _progress = (_timeline.currentFrame / _timeline.totalFrames) * 100;
		if (_timeline.currentFrame === _timeline.totalFrames - 1) _progress = 100;
		this.setState({ progress: _progress });
	}

	onCanvasTick(e) {
		this.updateProgress();

		this.props.olaRoot.off('tick', this.onCanvasTick);
		// check progress
		if (this.state.progress >= 100) {
			// progress is at 100%
			// if isFinished hasn't been set to true yet
			if (!this.state.isFinished) {
				this.setState({ isFinished: true });
				// call the callback function
				this.props.onFinished();
				this.setState({ isPaused: true });
				// remove listener
				e.remove();
			}
		} else {
			// we're not at 100%
			if (this.state.isFinished) {
				this.setState({ isFinished: false });
			}
		}
	}

	render() {
		return (
			<PBarWrapper
				isPaused={this.state.isPaused}
				progress={this.state.progress}
			>
				<ProgressBarFill style={{ width: `${100 - this.state.progress}%` }} />
			</PBarWrapper>
		);
	}
}

export default ProgressBar;
