import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

// base style
const ContainerBase = styled.div`
	box-sizing: border-box;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: ${(props) => props.justify};
	align-items: ${(props) => props.align};
	flex-direction: ${(props) => props.direction};
`;
// outer base style
const OuterContainer = styled(ContainerBase)`
	box-shadow: 0px 0px 9px #222;
	background-color: #2e445b;
	padding: 10px;
`;

// inner base style
const InnerContainer = styled(ContainerBase)`
	background-color: #1f2933;
`;

export default class Container extends React.Component {
	static propTypes = {
		/** width of the container */
		width: propTypes.string,
		/** height of the container */
		height: propTypes.string,
		/** direction of layout */
		direction: propTypes.oneOf(['row', 'column']),
		/** justify-content */
		justify: propTypes.oneOf(['center', 'flex-start']),
		/** align-items */
		align: propTypes.oneOf(['center', 'flex-start']),
		/** type of container */
		type: propTypes.oneOf(['inner', 'outer']),
	};

	static defaultProps = {
		width: '100%',
		height: '100%',
		direction: 'row',
		justify: 'flex-start',
		align: 'flex-start',
		type: 'outer',
	};

	render() {
		return (
			<>
				{this.props.type === 'outer' ? (
					<OuterContainer {...this.props}>{this.props.children}</OuterContainer>
				) : (
					<InnerContainer {...this.props}>{this.props.children}</InnerContainer>
				)}
			</>
		);
	}
}
