import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.button`
	display: block;
	height: 40px;
	background-color: #1f2933;
	margin-bottom: 6px;
	padding: 10px;
	display: flex;
	align-items: center;
	transition: background-color 250ms ease-out;
	border: none;
	cursor: pointer;
	border-radius: 4px;
	color: #9fb3c8;
	width: ${(props) => props.width};
	max-width: 380px;
	outline: 0;
	box-shadow: 0 2px 2px #1d2025;

	&:focus {
		outline: none;
	}

	&:hover {
		background-color: #102a43;
	}
	&.active {
		background-color: #9fb3c8;
		color: #32322b;
	}
`;

class LabeledButton extends React.Component {
	static propTypes = {
		/** onclick handler for the button */
		onClick: propTypes.func,
		/** label for the button */
		label: propTypes.string,
		/** identifier to retrieve object props */
		id: propTypes.string,
		/** whether this is the active button or not */
		isActive: propTypes.bool,
		/** override the default width */
		width: propTypes.string,
	};

	static defaultProps = {
		width: '100%',
	};

	render() {
		return (
			<Wrapper
				id={this.props.id}
				onClick={this.props.onClick}
				className={this.props.isActive ? 'active' : ''}
				width={this.props.width}
			>
				{this.props.label}
			</Wrapper>
		);
	}
}

export default LabeledButton;
