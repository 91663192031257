import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faPlay,
	faPause,
	faStepBackward,
	faStepForward,
	faSyncAlt,
	faMapMarker,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
	faPlay,
	faPause,
	faStepBackward,
	faStepForward,
	faSyncAlt,
	faMapMarker
);

// wrapper for the common media buttons
const CommonMB = styled.button`
	width: 40px;
	height: 40px;
	text-align: center;
	font-size: 18px;
	cursor: pointer;
	outline: none;
	color: #9fb3c8;
	border: 1px solid #1d2025;
	background-color: #1d2025;
	border-radius: 4px;
	box-shadow: 0 2px 2px #1d2025;
	transition: color 250ms ease-out, background-color 250ms ease-out;
	&:focus {
		outline: none;
	}
	&:hover {
		background-color: #102a43;
	}
`;

// wrapper for the frame jump buttons
// extends the common wrapper
const FrameJumpMB = styled(CommonMB)`
	height: 20px;
	width: 26px;
	position: absolute;
	top: 0;
	transform: translateX(-50%);
	left: ${(props) => props.percentage};
	margin-top: 4px;
	
	svg {
		transform: rotate(180deg);
	}
	
	/* common when percentage is 0% or 100% */
	${(props) =>
		(props.percentage === '0%' || props.percentage === '100%') &&
		css`
			transform: none;
		`}

	/* when the percentage is 0% */
	${(props) =>
		props.percentage === '0%' &&
		css`
			left: 0;
			text-align: left;
			svg {
				transform: rotate(135deg);
			}
		`}

	/* when the position is 100% */
	${(props) =>
		props.percentage === '100%' &&
		css`
			right: 0;
			left: unset;
			svg {
				transform: rotate(-135deg);
			}
		`}
`;

class MediaButton extends React.Component {
	static propTypes = {
		/** the type of media button */
		type: propTypes.string,
		/** the icon to be used */
		icon: propTypes.string,
		/** the click hander */
		onClick: propTypes.func,
		/** used by the frame jump button to position it on the timeline */
		percentage: propTypes.string,
	};

	static defaultProps = {
		type: 'control',
		onClick: null,
		icon: 'play',
		percentage: '0%',
	};

	render() {
		return this.props.type === 'control' ? (
			<CommonMB onClick={this.props.onClick}>
				<FontAwesomeIcon icon={this.props.icon} />
			</CommonMB>
		) : (
			<FrameJumpMB
				percentage={this.props.percentage}
				onClick={this.props.onClick}
			>
				<FontAwesomeIcon icon={this.props.icon} />
			</FrameJumpMB>
		);
	}
}

export default MediaButton;
