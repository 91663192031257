import React from 'react';
import Label from '../../_atoms/Label';
import Container from '../../_atoms/Container';
import propTypes from 'prop-types';

class InstructionalBox extends React.Component {
	static propTypes = {
		/** the text to display */
		text: propTypes.string,
	};
	render() {
		return (
			<Container type='inner' align='center' justify='center'>
				<Label text={this.props.text} />
			</Container>
		);
	}
}

export default InstructionalBox;
