import React from 'react';
import propTypes from 'prop-types';
import LabeledButton from '../../_atoms/LabeledButton';

class AdUnitNav extends React.Component {
	static propTypes = {
		/** json object */
		data: propTypes.object,
	};
	render() {
		return (
			<nav style={{ width: '100%', maxWidth: '400px' }}>
				{Object.keys(this.props.data).map((k) => (
					<LabeledButton
						isActive={this.props.activeItem === k}
						id={k}
						key={k}
						label={k}
						onClick={this.props.onClick}
					/>
				))}
			</nav>
		);
	}
}

export default AdUnitNav;
