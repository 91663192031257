import React from 'react';
import Container from '../_atoms/Container';
import LoadingBox from '../_molecules/LoadingBox';
import ProgressBar from '../_atoms/ProgressBar';
import AdUnitNav from '../_molecules/AdUnitNav';
import MediaControls from '../_molecules/MediaControls';
import FrameJumpBar from '../_molecules/FrameJumpBar';
import InstructionalBox from '../_molecules/InstructionalBox';
import withFilelist from '../_utilities/WithFilelist';

import { Redirect } from 'react-router-dom';
/**
 * The ReviewBase class
 */
const OLA_FPS = 24;

class ReviewBase extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
			isLoading: true,
			activeBanner: '',
			olaRoot: null,
			useMediaControls: false,
			isPaused: false,
		};

		// interval used when waiting for the banner root object to become available
		this.olaRootInterval = null;

		// refs
		this.iframe = React.createRef();
		this.progressBar = React.createRef();

		// bindings
		this.onAdUnitSelect = this.onAdUnitSelect.bind(this);
		this.onIframeLoad = this.onIframeLoad.bind(this);

		// media control handler bindings
		this.onStepForwardHandler = this.onStepForwardHandler.bind(this);
		this.onStepBackwardHandler = this.onStepBackwardHandler.bind(this);
		this.onReplayHandler = this.onReplayHandler.bind(this);
		this.onPlayPauseHandler = this.onPlayPauseHandler.bind(this);
		this.onOlaFinished = this.onOlaFinished.bind(this);
		this.onFrameJump = this.onFrameJump.bind(this);
		this.props.registerFetchCallback(this._onFetchCallback);
	}

	/**
	 * callback function passed to the HOC when data has been loaded / fetched
	 * checks whether the json data passed includes an error and sets
	 * the state data to either the error, or to the data passed
	 * @param {object} obj
	 */
	_onFetchCallback = (obj) => {
		if (obj.data.error) this.setState({ data: obj.data, isLoading: false });
		else {
			// generate the path to the banners
			let _path = `${process.env.PUBLIC_URL}/banners${this.props.match.url}`;
			this.setState({
				data: obj.data,
				path: _path,
			});
			setTimeout(() => this.setState({ isLoading: false }), 1000);
		}
	};

	/**
	 * when an ad unit has been clicked
	 * @param {event} e
	 */
	onAdUnitSelect(e) {
		let _key = e.currentTarget.getAttribute('id');
		this.setState({
			activeBanner: _key,
		});
	}

	/**
	 * called when the iframe loads its content
	 * @param {event} e
	 */
	onIframeLoad(e) {
		// create a counter
		let counter = 0;
		// amount of ticks of the interval
		// before we give up waiting for the ola root object
		let waitLimit = 20;
		// clear existing interval if one exists
		if (this.olaRootInterval) clearInterval(this.olaRootInterval);
		// set new interval
		this.olaRootInterval = setInterval(() => {
			if (this.iframe.current.contentWindow.DPOLARoot) {
				// update the state with the root object
				// set isPaused to false as the ola will play automatically
				this.setState({
					isPaused: false,
					olaRoot: this.iframe.current.contentWindow.DPOLARoot,
				});
				// start the progress bar
				this.progressBar.current.start();

				// clear the interval
				clearInterval(this.olaRootInterval);
			}
			// if we've reached the wait limit, clear the interval
			if (counter >= waitLimit) clearInterval(this.olaRootInterval);
			// increase the counter
			counter++;
		}, 100);
	}

	/**
	 * media control stepbackward button handler
	 * this will advance the timeline ~1 second
	 * update OLA_FPS constant if necessary.
	 * @param {event} e
	 */
	onStepBackwardHandler(e) {
		let _timeline = this.state.olaRoot;
		let _currentFrame = _timeline.currentFrame;
		let _targetFrame =
			_timeline.currentFrame > OLA_FPS ? _currentFrame - OLA_FPS : 0;
		this.state.isPaused
			? _timeline.gotoAndStop(_targetFrame)
			: _timeline.gotoAndPlay(_targetFrame);

		if (this.state.isPaused) this.progressBar.current.updateProgress();
	}

	/**
	 * media control stepforward button handler
	 * this will advance the timeline ~1 second.
	 * update OLA_FPS constant if necessary.
	 * @param {event} e
	 */
	onStepForwardHandler(e) {
		let _timeline = this.state.olaRoot;
		let _currentFrame = _timeline.currentFrame;
		let _totalFrames = _timeline.totalFrames;
		let _endFrame = _totalFrames - _currentFrame <= OLA_FPS;
		let _targetFrame = _endFrame ? _totalFrames - 1 : _currentFrame + OLA_FPS;

		if (this.state.isPaused) {
			_timeline.gotoAndStop(_targetFrame);
		} else {
			if (_targetFrame >= _totalFrames) {
				_timeline.gotoAndStop(_totalFrames - 1);
				this.setState({ isPaused: true });
			} else {
				_timeline.gotoAndPlay(_targetFrame);
			}
		}
		if (this.state.isPaused) this.progressBar.current.updateProgress();
	}

	/**
	 * media control replay button handler
	 * places the playhead at the first frame.
	 * @param {event} e
	 */
	onReplayHandler(e) {
		let _timeline = this.state.olaRoot;

		if (this.state.isPaused) {
			_timeline.gotoAndStop(1);
			this.progressBar.current.updateProgress();
		} else _timeline.gotoAndPlay(1);
	}

	/**
	 * media control play / pause button handler
	 * pauses or plays the ola.
	 * @param {event} e
	 */
	onPlayPauseHandler(e) {
		let _timeline = this.state.olaRoot;
		this.setState({ isPaused: !this.state.isPaused });
		if (this.state.isPaused) {
			this.progressBar.current.start();
			_timeline.currentFrame >= _timeline.totalFrames - 1
				? _timeline.gotoAndPlay(1)
				: _timeline.play();
		} else {
			this.progressBar.current.pause();
			_timeline.stop();
		}
	}

	/**
	 * media control frame jump button handler
	 * puts the animation at the frame passed.
	 * @param {frame} frame the frame to jump to
	 */
	onFrameJump(frame) {
		if (this.state.isPaused) {
			this.state.olaRoot.gotoAndStop(frame);
			this.progressBar.current.updateProgress();
		} else {
			this.state.olaRoot.gotoAndPlay(frame);
		}
	}

	/**
	 * callback function for when the ola has finished playing.
	 */
	onOlaFinished() {
		this.setState({ isPaused: true });
	}

	componentDidMount() {
		// this._getData();
	}

	render() {
		return (
			<>
				<Container width='100%' height='100vh' justify='center' align='center'>
					{this.state.isLoading ? (
						<LoadingBox />
					) : this.state.data.error ? (
						<Redirect to='/' />
					) : this.state.activeBanner === '' ? (
						<InstructionalBox text='Select an item to the right.' />
					) : (
						<iframe
							width={this.state.data[this.state.activeBanner].width}
							height={this.state.data[this.state.activeBanner].height}
							scrolling='no'
							title='Baner display'
							ref={this.iframe}
							onLoad={this.onIframeLoad}
							src={
								this.state.activeBanner !== ''
									? `${this.state.path}/${this.state.activeBanner}/${
											this.state.data[this.state.activeBanner].html
									  }`
									: ''
							}
						/>
					)}
				</Container>
				<Container width='400px' height='100vh' direction="column">
					{this.state.isLoading ? (
						<></>
					) : (
						<>
							<AdUnitNav
								activeItem={this.state.activeBanner}
								data={this.state.data}
								onClick={this.onAdUnitSelect}
							/>
							<div style={{ marginTop: '10px', width: '100%' }}>
								{this.state.olaRoot && this.state.activeBanner !== '' ? (
									<>
										<MediaControls
											isPaused={this.state.isPaused}
											onStepBackward={this.onStepBackwardHandler}
											onStepForward={this.onStepForwardHandler}
											onPlayPause={this.onPlayPauseHandler}
											onReplay={this.onReplayHandler}
										/>
										<ProgressBar
											ref={this.progressBar}
											olaRoot={this.state.olaRoot}
											onFinished={this.onOlaFinished}
											isPaused={this.state.isPaused}
										/>
										<FrameJumpBar
											data={this.state.olaRoot}
											onClick={this.onFrameJump}
										/>
									</>
								) : !this.state.olaRoot && this.state.activeBanner !== '' ? (
									<></>
								) : (
									<></>
								)}
							</div>
						</>
					)}
				</Container>
			</>
		);
	}
}

const Review = withFilelist(ReviewBase);

export default Review;
