import React from 'react';
import Container from '../_atoms/Container';
import LoadingBox from '../_molecules/LoadingBox';
import BreadCrumb from '../_molecules/BreadCrumb';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import withFilelist from '../_utilities/WithFilelist';

// simple styled component
const Listing = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;

	p {
		margin: 0 auto 0 0;
	}

	a:not(:first-child) {
		margin-left: 20px;
	}
`;

/**
 * retrieves a file list and allows use to browse file list
 * creates "view" and "copy" links for ad campaigns
 */
class BrowseBase extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
			isLoading: true,
		};

		// register callback with HOC
		this.props.registerFetchCallback(this._onFetchCallback);

		// bindings
		this.onCopy = this.onCopy.bind(this);
	}

	/**
	 * when 'copy review link' is selected
	 * this will copy the campaigns review path to the clipboard
	 * @param {event} e
	 */
	onCopy(e) {
		e.preventDefault();
		navigator.clipboard.writeText(
			`${window.location.origin}${e.currentTarget.getAttribute('href')}`
		);
	}

	/**
	 * callback function passed to the HOC when data has been loaded / fetched
	 * @param {obj} obj json data
	 */
	_onFetchCallback = (obj) => {
		this.setState({ data: obj.data });
		setTimeout(() => this.setState({ isLoading: false }), 500);
	};

	/**
	 * determines whether the current level is a campaign or has subcampaigns
	 * this is used to display actionable items for the campaign
	 * @param {object} data the campaign data
	 */
	_hasSubcampaign(data) {
		let _firstChildKey = Object.keys(data)[0];
		let _firstChild = data[_firstChildKey];
		return !_firstChild.hasOwnProperty('html');
	}

	render() {
		let {
			match: {
				url,
				params: { brandId, campaignId },
			},
		} = this.props;

		// data is provided by HOC
		let _data;
		if (Object.keys(this.state.data).length !== 0) {
			_data = this.state.data;
			// update the data when a brand was selected
			if (brandId) _data = this.state.data[brandId];
			// update the data when a campaign is selected
			if (campaignId) _data = this.state.data[brandId][campaignId];
		}

		return (
			<Container width='1200px' height='620px' direction='column'>
				{this.state.isLoading ? (
					<LoadingBox />
				) : (
					<>
						<Container
							style={{ marginBottom: '10px' }}
							height='60px'
							type='inner'
							align='center'
						>
							<BreadCrumb homeLabel='clients' path={url} />
						</Container>
						<Container type='inner' direction='column' style={{ flex: '1 1' }}>
							{Object.keys(_data).map((k) => {
								let _hasSubcampaigns = this._hasSubcampaign(_data[k]);
								let _copyLink = `${url.split('browse')[1]}/${k}`;
								return (
									<Listing key={k}>
										{_hasSubcampaigns ? (
											<Link to={`${url}/${k}`}>{k}</Link>
										) : (
											<p>{k}</p>
										)}
										{_hasSubcampaigns ? null : (
											<>
												<Link to={_copyLink}>Visit</Link>
												<a
													href={_copyLink}
													data-clipboard-text={_copyLink}
													onClick={this.onCopy}
												>
													copy review link
												</a>
											</>
										)}
									</Listing>
								);
							})}
						</Container>
					</>
				)}
			</Container>
		);
	}
}

const Browse = withFilelist(BrowseBase);

export default Browse;
