import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

body {
	background-color: #1d2025;
	font-size: 14px;
	color: #9fb3c8;
	font-family: sans-serif;
}

a,
a:visited
{
	color: inherit;
}

#root {
	
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	height: 100vh;
}

iframe {
	border: none;
	background-color: #1f2933;
	overflow: hidden;
	transition: width 150ms ease-out, height 150ms ease-out;
	border: 1px solid #1f2933;
}
`;

/**
 * Globally Styled Higher Order Component
 * @param {Component} WrappedComponent
 */
function ComponentWithGlobalStyle(WrappedComponent) {
	return class extends React.Component {
		render() {
			return (
				<>
					<GlobalStyle />
					<WrappedComponent {...this.props} />
				</>
			);
		}
	};
}

export default GlobalStyle;
export { ComponentWithGlobalStyle };
