import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import MediaButton from '../../_atoms/MediaButton';

class MediaControls extends React.Component {
	static propTypes = {
		/** handler for stepping backward 1s */
		onStepBackward: propTypes.func,
		/** handler for stepping forward 1s */
		onStepForward: propTypes.func,
		/** handler for play / pause button */
		onPlayPause: propTypes.func,
		/** handler for replay */
		onReplay: propTypes.func,
		/** whether the timeline is paused or not */
		isPaused: propTypes.bool,
	};

	static defaultProps = {
		onStepBackward: null,
		onStepForward: null,
		onPlayPause: null,
		onReplay: null,
		isPaused: false,
	};

	render() {
		const Wrapper = styled.div`
			display: flex;
			justify-content: center;
			width: 100%;
			margin-bottom: 10px;

			button {
				margin: 0 4px;
			}
		`;

		return (
			<Wrapper>
				<MediaButton icon='step-backward' onClick={this.props.onStepBackward} />
				<MediaButton icon={'sync-alt'} onClick={this.props.onReplay} />
				<MediaButton
					icon={this.props.isPaused ? 'play' : 'pause'}
					onClick={this.props.onPlayPause}
				/>
				<MediaButton icon={'step-forward'} onClick={this.props.onStepForward} />
			</Wrapper>
		);
	}
}

export default MediaControls;
