import React from 'react';
import propTypes from 'prop-types';
import MediaButton from '../../_atoms/MediaButton';

class FrameJumpBar extends React.Component {
	static propTypes = {
		/** ola root object */
		data: propTypes.object,
	};

	render() {
		return (
			<div
				style={{
					position: 'relative',
					width: '100%',
					maxWidth: '400px',
					height: '30px',
				}}
			>
				{this.props.data.labels.map((k, idx) => {
					let _percentage;
					if (k.position === 1) {
						_percentage = '0%';
					} else if (k.position === this.props.data.totalFrames) {
						_percentage = '100%';
					} else {
						_percentage = `${Math.round(
							(k.position / this.props.data.totalFrames) * 100
						)}%`;
					}
					let _btn = (
						<MediaButton
							key={idx}
							type='frame-jump'
							icon='map-marker'
							percentage={_percentage}
							onClick={(e) => this.props.onClick(k.position)}
						/>
					);
					return k.label.indexOf('Frame_') === 0 ? _btn : null;
				})}
			</div>
		);
	}
}

export default FrameJumpBar;
