import React from 'react';
import ReactDOM from 'react-dom';
import Browse from './components/_views/Browse';
import Review from './components/_views/Review';
import Index from './components/_views/Index';
import { ComponentWithGlobalStyle } from './components/_utilities/GlobalStyle';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	useLocation,
} from 'react-router-dom';

// Router
export default function App() {
	const { pathname } = useLocation();
	return (
		<Switch>
			{/* redirects anything with a trailing slash to non-trailing slash */}
			<Redirect from='/:url*(/+)' to={pathname.slice(0, -1)} />
			<Route path={`/browse/:brandId?/:campaignId?`} component={Browse} />
			<Route
				path={`/:brandId/:campaignId/:subcampaignId?`}
				component={Review}
			/>
			<Route path='/' component={Index} />
		</Switch>
	);
}

const StyledApp = ComponentWithGlobalStyle(App);
ReactDOM.render(
	<Router>
		<StyledApp />
	</Router>,
	document.getElementById('root')
);
