import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

const LabelBase = styled.p`
	color: #9fb3c8;
	font-family: sans-serif;
	font-size: ${(props) => props.size};
`;

class Label extends React.Component {
	static propTypes = {
		/** font-size */
		size: propTypes.string,
	};

	static defaultProps = {
		size: '14px',
	};

	render() {
		return <LabelBase size={this.props.size}>{this.props.text}</LabelBase>;
	}
}

export default Label;
