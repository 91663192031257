import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BreadCrumbDivider = styled.span`
	color: inherit;
	display: inline-block;
`;

class BreadCrumb extends React.Component {
	static propTypes = {
		/** the path to create a breadcumb from */
		path: propTypes.string,
		/** rename the first part */
		homeLabel: propTypes.string,
	};

	_parsePath(path) {
		let _path = path.indexOf('/') === 0 ? path.substr(1) : path;
		let _parts = _path.split('/');

		let _arr = [];

		_parts.forEach((part) => {
			let _uri = path.substring(0, path.indexOf(part) + part.length);
			let _obj = { name: part, path: _uri };
			_arr.push(_obj);
		});

		if (this.props.homeLabel) _arr[0].name = this.props.homeLabel;
		return _arr;
	}

	render() {
		let _pathArr = this._parsePath(this.props.path);
		return (
			<>
				{_pathArr.map((o, idx) => {
					let _isLast = idx >= _pathArr.length - 1;
					return (
						<React.Fragment key={idx}>
							{_isLast ? (
								<span style={{ padding: '10px' }}>{o.name}</span>
							) : (
								<Link style={{ padding: '10px' }} to={o.path}>
									{o.name}
								</Link>
							)}
							{idx < _pathArr.length - 1 ? (
								<BreadCrumbDivider key={`d${idx}`}>»</BreadCrumbDivider>
							) : null}
						</React.Fragment>
					);
				})}
			</>
		);
	}
}

export default BreadCrumb;
