import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const LoadingAnimation = keyframes`
	100%{transform: rotate(360deg)}
`;

const LoadingIconBase = styled.i`
	color: #9fb3c8;
	font-size: 22px;
	padding: 4px;
	svg {
		animation: 1s ${LoadingAnimation} linear infinite;
	}
`;

class LoadingIcon extends React.Component {
	render() {
		return (
			<LoadingIconBase>
				<FontAwesomeIcon icon={faSyncAlt} />
			</LoadingIconBase>
		);
	}
}

export default LoadingIcon;
