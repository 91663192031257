import React from 'react';
import Container from '../../_atoms/Container';
import LoadingIcon from '../../_atoms/LoadingIcon';

class LoadingBox extends React.Component {
	render() {
		return (
			<Container type='inner' align='center' justify='center'>
				<LoadingIcon />
			</Container>
		);
	}
}

export default LoadingBox;
